<template>
    <div>
        <el-input v-show="types==2" placeholder="请输入关键词搜索" v-model="kw" clearable></el-input>
        <ul class="rz-ul">
            <li class="list" :key="m.id" v-for="(m, index) in list" v-if="m.catename.indexOf(kw)!=-1 || types!=2">
                <div class="content flex-bt">
                    <div class="left">
                        <span class="name">{{m.catename}}</span>
                        <span class="check" v-if="m.status!=1">【{{m.status=-1?'已驳回':'审核中'}}】</span>
                    </div>
                    <div class="right">
                        <span class="num">{{m.dy_count}}篇作品</span>
                        <i class="el-icon-arrow-down" @click.stop="m.show = !m.show ? 1: 0"></i>
                    </div>
                </div>
                <div class="reason" v-if="m.status==-1 && m.reason">原因：{{m.reason}}</div>
                <div class="detail" v-show="m.show">
                    <div class="d-box" v-if="m.hospital"><span class="name">机构：</span>{{m.hospital}}</div>
                    <div class="d-box" v-if="m.realname"><span class="name">姓名：</span>{{m.realname}}</div>
                    <div class="d-box" v-if="m.occupation"><span class="name">职称/职业：</span>{{m.occupation}}</div>
                    <div class="d-box" style="flex:1"><span class="name">证明图片：</span> <img :src="rImg(m.img_a)" :onerror="errImg" alt=""><img :src="rImg(m.img_b)" :onerror="errImg" alt=""></div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
    import {
        mapGetters,
        mapMutations
    } from 'vuex';
    export default {
        components: {},
        data() {
            return {
                types: 2, // 2经历 1擅长
                list: [],
                kw: ''
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
        },
        mounted() {
            // 在组件被挂载之后调用。
            this.getData();
        },
        methods: {
            // ...mapMutations([])
            async getData() {
                this.types = this.$route.query.id || 2;
                let {
                    data
                } = await this.$y_ajax('api/nihao/authed', {
                    types: this.types
                })
                data.forEach(v => {
                    v.show = 0;
                });
                this.list = data;
            }
        },
        computed: {
            // 计算属性
            // ...mapGetters([])
        },
        watch: {
            // 监听
            $route() {
                this.getData();
            },
        }
    };
</script>

<style lang="less" scoped>
    .list {
        padding: 15px;
        border-bottom: 1px solid #eee;
    }

    .content {
        .left {
            .name {
                font-size: 18px;
            }
        }

        .check {
            color: #aaa;
        }

        .num {
            color: #757575;
        }

        .el-icon-arrow-down {
            margin-left: 10px;
            cursor: pointer;
        }
    }

    .reason {
        color: red;
        padding: 10px;
        background: #f3f3f3;
        margin-top: 7px;
    }

    .detail {
        display: flex;
        flex-wrap: wrap;
        margin-top: 7px;
        animation: show 0.5s;
        -webkit-animation: show 0.5s;
        transform-origin: 0 0;

        .d-box {
            width: 33%;
            display: inline-flex;
            align-items: flex-start;

            .name {
                color: #aaa;
            }

            img {
                width: 50px;
                height: 50px;
                margin-right: 10px;
                border-radius: 7px;
            }
        }
    }

    @keyframes show {
        from {
            transform: scaleY(0);
        }

        to {
            transform: scaleY(1);
        }
    }

    @-webkit-keyframes show {
        from {
            transform: scaleY(0);
        }

        to {
            transform: scaleY(1);
        }
    }
</style>